.loader-center {
  width: 30px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  
}

.home_banner {
  display: flex;
  transition: all 0.5s;
  z-index: 997;
  height: 85px;
  box-shadow: 0px 2px 20px rgb(1 41 112 / 10%);
  background-color: #fff;
  padding-left: 5px;
  justify-content: space-between;
  width: 100%;
  position: fixed;
}

.logo {
  height: 90px;
  width: 125px;
}

.logo-text {
  font-size: 23px;
  font-weight: 700;
  color: #11B9A1;
  font-family: "Nunito", sans-serif;
  padding-top: 10px;
}

.right-section {
  padding-top: 10px;
}

/* searchbar css */

.header .search-bar {
  min-width: 360px;
  padding: 0 20px;
}

@media (max-width: 1199px) {
 .search-bar {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    padding: 20px;
    box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
    background: white;
    z-index: 9999;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
  }

  .search-bar-show {
    top: 85px;
    visibility: visible;
    opacity: 1;
  }
}

.search-form {
  width: 400px;
}

.search-form input {
  border: 0;
  font-size: 14px;
  color: #012970;
  border: 1px solid rgba(1, 41, 112, 0.2);
  padding: 7px 38px 7px 8px;
  border-radius: 3px;
  transition: 0.3s;
  width: 100%;
}

.search-form input:focus,
.header .search-form input:hover {
  outline: none;
  box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
  border: 1px solid rgba(1, 41, 112, 0.3);
}

.search-form button {
  border: 0;
  padding: 0;
  margin-left: -30px;
  background: none;
}

 .search-form button i {
  color: #012970;
}
.image-card{
  height: 120px;
  width:120px !important;
  padding: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.image-card-main{
  height: 85px;
  width: 163px !important;
  padding: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}
.settings{
  position: absolute;
    right: 0;
    margin-right: 10px;
    font-size: 18px;
    top: 1px;


}
.toggle-left{
  font-size: 35px;
  position: absolute;
  top: -10px;
  color: green;
  z-index: 99;
}
.catType{
  justify-content: center;
}
.card-desc{
  margin-bottom: 30%;
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
}

.card-desc-small{
  margin-bottom: 20%;
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
}
.width-unset{
  width: unset !important;
}
.imagePreview{
  cursor: pointer;
  position: relative;
  top: 0;
  left: 0;
    margin: 10px;
    border: 1px solid #262626;
}
.file-uploader{
  min-width: unset !important;
    max-width: 200px !important;
    height: 160px !important;
    width: 200px;
}

.parent-imageUpload{
  position: relative;
  top: 0;
  left: 0;
}

.image-cross {
  position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99999;
    padding: 5px;
    cursor: pointer;
}

.image-select-default {
  position: absolute;
    top: 10px;
    left: 15px;
    z-index: 99999;
    cursor: pointer;
}
.search-right{
  position: absolute;
    right: 10%;
    z-index: 999;
}


 
  /* Breadcrumbs */
  .breadcrumb {
    font-size: 14px !important;
    font-family: "Nunito", sans-serif !important;
    color: #899bbd !important;
    font-weight: 600 !important;
  }
  
  .breadcrumb a {
    color: #899bbd;
    transition: 0.3s;
  }
  
  .breadcrumb a:hover {
    color: #51678f;
  }
  
  .breadcrumb .breadcrumb-item::before {
    color: #899bbd;
  }
  
  .breadcrumb .active {
    color: #51678f;
    font-weight: 600;
  }

   
  /*--------------------------------------------------------------
  # Page Title
  --------------------------------------------------------------*/
  .pagetitle {
    margin-bottom: 10px;
  }
  
  .pagetitle h1 {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 600;
    color: #012970;
  }