body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bold-font{
  font-weight: 600;
  font-size: 16px;
}
.bold-font-small{
  font-weight: 600;
  font-size: 14px;
}
.cursor{
  cursor: pointer;
}
.m-10{
  margin: 10px;
}
.mr-10{
  margin-right: 10px;
}
.ml-20{
  margin-left: 20px;
}
.ml-10{
  margin-left: 10px;
}
.mt-20{
  margin-top: 20px;
}
.mr-5{margin-right:5px}
.primary-button{
  background-color: #048ED5!important;
  border: unset !important
}
.flex-row{
  display: flex;
  flex-direction: row;
}
.flex-column{
  display: flex;
  flex-direction: column;
}
.text-center{
  text-align: center;
}
.pointer-cursor{
  cursor: pointer;
}
.color-red{
  color: red;
}
.strike{
  text-decoration: line-through;
}
.btn-view{
  position: absolute;
    bottom: 10px;
    right: 0;
    left: 0;
    margin: 10px;
}
.justify{
  justify-content: space-between;
  width: 100%;
}
.color-green{
  background-color:rgb(6, 39, 39) !important
}

.txt-eclipse{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
