.row-home{
    display: flex;
    flex-direction: row; 
  }

  .column-home{
    display: flex;
    flex-direction: column; 
  }
  .product-item-image{
    width: 100px;
    max-height: 120px;
  }
  .product-list-row{
    align-self: center;
    margin-left: 20px !important;
  }
  .product-setting{
    font-size: 20px !important;

  }
  .product-toggle-left{
    font-size: 40px;
    color:green;
    position: absolute;
    right: 0;
    margin-right: 5px;
    bottom: -5px;
  }