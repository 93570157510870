  
  .main_container{
    padding: 20px 30px;
    transition: all 0.3s;
    margin-left: 310px;
    margin-top: 85px;
 
  }
  .rightContainer{
    display: flex;
    flex-direction: column;
    margin-left: 15px
  }
  .row-home{
    display: flex;
    flex-direction: row; 
  }

  .column-home{
    display: flex;
    flex-direction: column; 
  }


 