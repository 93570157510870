

  /* card */
  .info-card{
    margin-bottom: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgb(1 41 112 / 10%);
    background: white;
    width: 200px;
    height: 150px;
  }
  .order-card{
    margin-bottom: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgb(1 41 112 / 10%);
    background: white;
    width: 350px;
    min-height: 580px;
  }
  .graph-card{
    margin-bottom: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgb(1 41 112 / 10%);
    background: white;
    width: 700px;
    min-height: 450px;
  }
  .product-card{
    margin-bottom: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgb(1 41 112 / 10%);
    background: white;
    margin-left: 0%;
    min-height: 550px;
    cursor: pointer;
  }
  .right-order-item{
    padding-left: 15px;
    flex-direction: row;
    font-size: 15px;
    border-left: 10px solid #c4c410;
    margin-top: 10px;
  }
  .right-order-item-green{
    padding-left: 15px;
    flex-direction: row;
    font-size: 15px;
    border-left: 10px solid #10832f;
    margin-top: 10px;
  }
  .right-order-item-light-green{
    padding-left: 15px;
    flex-direction: row;
    font-size: 15px;
    border-left: 10px solid #7bdf96;
    margin-top: 10px;
  }
  .right-order-item-gray{
    padding-left: 15px;
    flex-direction: row;
    font-size: 15px;
    border-left: 10px solid #666866;
    margin-top: 10px;
  }
  .right-order-item-purple{
    padding-left: 15px;
    flex-direction: row;
    font-size: 15px;
    border-left: 10px solid #9e80f1;
    margin-top: 10px;
  }
  
  .right-order-item span{
    font-weight: 600;
  }
  .tarck-text{
    margin-top: 3px;
    color: #1a1ad7;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }
  .show-more{
    margin-top: 3px;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
    position: absolute;
    right: 0;
    margin-right: 30px;
  }
  .order-title{
    font-weight: 600;
    font-size: 14px;
    color: #048ED5
  }
  .catalogue-icon{
    height: 85px;
    padding: 5px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .card-top{
    position: absolute;
  }
  .title-container{
    display: flex;
    justify-content: space-between;
  }
  .primary-button-disable{
    background-color: #d9e0e3!important;
    border: unset !important;
    opacity: 0.5;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }