.row-home{
  display: flex;
  flex-direction: row;
 
  }

  .column-home{
    display: flex;
    flex-direction: column; 
  }
  .name{
    /* align-self: center; */
    /* margin: 10px; */
    font-size: 22px;
    font-weight: 700;
  }
  .offline{
    background: red;
    border-radius: 50px;
    padding: 3px;
  }

  .online{
    background: green;
    border-radius: 50px;
    padding: 3px;
  }
  .column-home span{
    font-weight: 600;
  }
  .wrapper-user{
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-bottom: 1px solid #eae5e5;
  }
  .trash{
    align-self: flex-end;
    color:red
  }