.row-home{
    display: flex;
    flex-direction: row; 
  }

  .column-home{
    display: flex;
    flex-direction: column; 
  }
  .red-left-border{
    border-left: 10px solid #dc3545;
  }
  .margin-top-3p{
margin-top: 3%;
  }
  .bg-black{
    background-color: black !important;
  }
  .bg-green{
    background-color: rgb(41, 77, 44) !important;
  }
  ul {
    list-style-type: none;
  }
  .list-partner{
    align-items: center;
    padding: 15px;
  }

  .wrapper-user{
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-bottom: 1px solid #eae5e5;
  }

  .prd-name{
    /* align-self: center; */
    /* margin: 10px; */
    font-size: 18px;
    font-weight: 700;
  }
